





























































































































































































































































































.specialTestDetail {
  .info_box {
    padding: 10px 20px;
    background-color: #f2f7fd;
    border-radius: 8px;
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .info {
      padding: 10px 50px;
      > div {
        margin-bottom: 8px;
        .label {
          min-width: 80px;
          text-align: right;
        }
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
}
